html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family:
        "Poppins",
        -apple-system,
        system-ui,
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    line-height: 1.3;
    width: 100%;
    min-height: 100%;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    background: #2e282a;
}

code {
    font-family: "Noto Sans Mono", "Roboto Mono", monospace;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
}

*,
::before,
::after {
    box-sizing: inherit;
}

/* width */
::-webkit-scrollbar {
    width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #2e282a;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #4a4044;
    border: 3px solid #2e282a;
    border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #4a4044;
}

#root {
    min-height: 100vh;
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: 100%;
}
